import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import MsgErroInput from "../../components/generic/MsgErroInput";
import api from "../../services/api";
import LoadingOverlay from "react-loading-overlay";
import banner from "../../assets/JORNADA-DE-ACOLHIMENTO.png";
import MaskedInput from "react-text-mask";
import { Link } from "react-router-dom";

export default function Login(props) {
    const [loading, setLoading] = React.useState(false);
    const camposLogin = {
        cpf: "",
        senha: "",
    };
    const schema = Yup.object().shape({
        cpf: Yup.string().required("Campo CPF obrigatório."),
        senha: Yup.string().required("Campo senha obrigatório."),
    });
    const handleSubmit = async ({ cpf, senha }) => {
        setLoading(true);
        const data = {
            cpf,
            senha,
        };
        try {
            const user = await api.post("auth", data);
            localStorage.setItem("token", user.data.token);
            localStorage.setItem("userNameLogged", user.data.userNameLogged);
            localStorage.setItem("super_admin", user.data.super_admin);
            localStorage.setItem("cpf", user.data.cpf);
            localStorage.setItem("email", user.data.email);
            localStorage.setItem("idUsuario", user.data.idUsuario);
            if (localStorage.getItem("super_admin") === "1") {
                props.history.push("/candidatos");
            } else {
                props.history.push("/como-funciona");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (!error.response) {
                // network error
                alert("Servidor de dados em manutenção. Tente novamente logo mais.");
            } else {
                alert(error.response.data.error);
            }
        }
    };
    return (
        <LoadingOverlay active={loading} spinner text="Carregando...">
            <div className="caixaLogin">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div
                                            className="col-lg-6 d-none d-lg-block bg-login-image"
                                            style={{
                                                background: `url(${banner})`,
                                            }}
                                        ></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">
                                                        Bem vindo à Jornada de Acolhimento do Colégio CAVE!
                                                    </h1>
                                                </div>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={camposLogin}
                                                    validationSchema={schema}
                                                    onSubmit={handleSubmit}
                                                    className="user"
                                                >
                                                    {({ errors, touched }) => (
                                                        <Form>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="cpf"
                                                                    children={({ field }) => (
                                                                        <MaskedInput
                                                                            {...field}
                                                                            placeholder="Informe seu CPF"
                                                                            className="form-control"
                                                                            mask={[
                                                                                /\d/,
                                                                                /\d/,
                                                                                /\d/,
                                                                                ".",
                                                                                /\d/,
                                                                                /\d/,
                                                                                /\d/,
                                                                                ".",
                                                                                /\d/,
                                                                                /\d/,
                                                                                /\d/,
                                                                                "-",
                                                                                /\d/,
                                                                                /\d/,
                                                                            ]}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors.cpf && touched.cpf ? (
                                                                    <MsgErroInput>{errors.cpf}</MsgErroInput>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group">
                                                                <Field
                                                                    type="password"
                                                                    name="senha"
                                                                    className="form-control form-control-user"
                                                                    placeholder="Informe sua senha"
                                                                />
                                                                {errors.senha && touched.senha ? (
                                                                    <MsgErroInput>{errors.senha}</MsgErroInput>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="custom-control small text-right">
                                                                    <Link to={"/esqueci-senha"} className="linkEsqueciSenha">Esqueci minha senha</Link>
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-danger btn-user btn-block"
                                                            >
                                                                Entrar
                                                            </button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                                <hr />
                                                <div className="text-right">
                                                    <Link to="/venha-para-o-cave" style={{ color: "#dc3545" }}>
                                                        Quero me inscrever
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
