import React from "react";
import ConteudoFaq from "../faq/components/ConteudoFaq";
import LogoSmall from "../../assets/logo-small.png";
import { Link } from "react-router-dom";

export default function FaqExterna() {
    return (
        <>
            <header>
            <div className="navbar navbar-dark box-shadow" id="divMenuLandingPage">
                <div className="container d-flex justify-content-between">
                    <Link to={"/venha-para-o-cave"} title="Jornada Acadêmica " className="navbar-brand d-flex align-items-center">
                    <img src={LogoSmall} alt="Logo Cave" />
                    </Link>
                </div>
            </div>
        </header>
            <div className="App wrapper">
                <ConteudoFaq/>
            </div>
            <footer className="footer">
                <div className="container p-2 text-center">
                    <span className="text-light">Cave 2023 © Todos os direitos reservados.</span>
                </div>
            </footer>
        </>
    );
}
