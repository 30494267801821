import { Formik, Form, Field } from "formik";
import React from "react";

export default function CheckboxNoticia({ triggerNextStep }) {
    const [btnVisible, setBtnVisible] = React.useState(true);
    const initialValores = {
        opcoes: "",
    };
    const handleSubmit = async (value) => {
        // console.log(value.opcoes);
        setBtnVisible(false);
        triggerNextStep({ value: value.opcoes.toString() });
    };
    return (
        <div>
            <Formik enableReinitialize initialValues={initialValores} onSubmit={handleSubmit} className="user">
                <Form>
                    <div className="row">
                        <div className="col col-12" style={{ marginBottom: "15px" }}>
                            Marque as opções abaixo:
                        </div>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Jornais impressos" id="1" />
                        <label htmlFor="1" className="form-check-label">
                            Jornais impressos
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Portais de notícia online" id="2" />
                        <label htmlFor="2" className="form-check-label">
                            Portais de notícia online
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Notícias através de redes sociais" id="3" />
                        <label htmlFor="3" className="form-check-label">
                            Notícias através de redes sociais
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Telejornal" id="4" />
                        <label htmlFor="4" className="form-check-label">
                            Telejornal
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Rádio" id="5" />
                        <label htmlFor="5" className="form-check-label">
                            Rádio
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Revistas" id="6" />
                        <label htmlFor="6" className="form-check-label">
                            Revistas
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field
                            type="checkbox"
                            className="form-check-input"
                            name="opcoes"
                            value="Não consumo"
                            id="7"
                        />
                        <label htmlFor="7" className="form-check-label">
                            Não consumo
                        </label>
                    </div>
                    {btnVisible && (
                        <div className="form-check text-center" style={{ marginTop: "15px" }}>
                            <button type="submit" className="btn btn-danger">
                                Enviar
                            </button>
                        </div>
                    )}
                </Form>
            </Formik>
        </div>
    );
}
