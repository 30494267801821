import { Formik, Form, Field } from "formik";
import React from "react";

export default function CheckboxInteresse({ triggerNextStep }) {
    const [btnVisible, setBtnVisible] = React.useState(true);
    const initialValores = {
        opcoes: "",
    };
    const handleSubmit = async (value) => {
        setBtnVisible(false);
        triggerNextStep({ value: value.opcoes.toString() });
    };
    return (
        <div>
            <Formik enableReinitialize initialValues={initialValores} onSubmit={handleSubmit} className="user">
                <Form>
                    <div className="row">
                        <div className="col col-12" style={{ marginBottom: "15px" }}>
                            Marque as opções abaixo:
                        </div>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Ensino" id="1" />
                        <label htmlFor="1" className="form-check-label">
                            Ensino
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field
                            type="checkbox"
                            className="form-check-input"
                            name="opcoes"
                            value="Metodologia diferenciada"
                            id="2"
                        />
                        <label htmlFor="2" className="form-check-label">
                            Metodologia diferenciada
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field
                            type="checkbox"
                            className="form-check-input"
                            name="opcoes"
                            value="Estrutura Física do Colégio"
                            id="3"
                        />
                        <label htmlFor="3" className="form-check-label">
                        Estrutura Física do Colégio
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Professores" id="4" />
                        <label htmlFor="4" className="form-check-label">
                            Professores
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Preparação pré-vestibular" id="5" />
                        <label htmlFor="5" className="form-check-label">
                            Preparação pré-vestibular
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Tradição do CAVE" id="6" />
                        <label htmlFor="6" className="form-check-label">
                            Tradição do CAVE
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Amigos no Colégio" id="7" />
                        <label htmlFor="7" className="form-check-label">
                            Amigos no Colégio
                        </label>
                    </div>
                    {btnVisible && (
                        <div className="form-check text-center" style={{ marginTop: "15px" }}>
                            <button type="submit" className="btn btn-danger">
                                Enviar
                            </button>
                        </div>
                    )}
                </Form>
            </Formik>
        </div>
    );
}
