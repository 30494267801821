import React, { memo } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import api from "../../services/api";
import CheckboxInteresse from "../chatbot/CheckboxInteresse";
// import user from "../../assets/img/user.png";
function ChatbotEtapa3({ perguntas, idUsuarioLogado }) {
    // console.log(perguntas);
    // console.log("etapa2");
    const theme = {
        background: "#F5F8FB",
        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
        headerBgColor: "#f9c647",
        headerFontColor: "#A32927",
        headerFontSize: "16px",
        botBubbleColor: "#A32927",
        botFontColor: "#fff",
        userBubbleColor: "#fff",
        userFontColor: "#4c4c4c",
    };
    const config = {
        width: "100%",
        height: "77vh",
        headerTitle: "ESCOLA",
        placeholder: "Responda aqui...",
        // userAvatar: `${user}`,
    };
    const name = localStorage.getItem("userNameLogged");

    const steps = [
        {
            id: "1",
            message: `Olá, ${name}! `,
            trigger: "2",
        },
        {
            id: "2",
            message: "Pronto para a terceira etapa do nosso bate-papo?",
            trigger: "3",
        },
        {
            id: "3",
            message: "Hoje eu gostaria de saber sobre a sua escola dos sonhos. Isso mesmo!",
            trigger: "4",
        },
        {
            id: "4",
            message: perguntas[0] ? ` - ${perguntas[0].pergunta}` : '', //Como seria a escola dos seus sonhos? Faça um breve relato.
            trigger: "5",
        },
        {
            id: "5",
            user: true,
            trigger: "6.1",
            metadata: {
                idPergunta: perguntas[0] ? `${perguntas[0].idPergunta}` : "-",
            },
        },
        {
            id: "6.1",
            message: `Sonhei junto com você, ${name}! `,
            trigger: "7.1",
        },
        {
            id: "7.1",
            message: `Aqui no Colégio CAVE acreditamos que construímos a escola dos sonhos juntos.`,
            trigger: "6",
        },
        {
            id: "6",
            message: perguntas[1] ? ` - ${perguntas[1].pergunta}` : '', //Explique com poucas palavras: o que você espera de uma escola de qualidade?
            trigger: "7",
        },
        {
            id: "7",
            user: true,
            trigger: "8.1",
            metadata: {
                idPergunta: perguntas[1] ? `${perguntas[1].idPergunta}` : "-",
            },
        },
        {
            id: "8.1",
            message: "Que bacana!",
            trigger: "8",
        },
        {
            id: "8",
            message: perguntas[2] ? ` - ${perguntas[2].pergunta}` : '', //Você pode me contar o que você já conhece sobre o Colégio CAVE?
            trigger: "9",
        },
        {
            id: "9",
            user: true,
            trigger: "10",
            metadata: {
                idPergunta: perguntas[2] ? `${perguntas[2].idPergunta}` : "-",
            },
        },
        {
            id: "10.1",
            message: "Legal!",
            trigger: "10",
        },
        {
            id: "10",
            message: perguntas[3] ? ` - ${perguntas[3].pergunta}` : '', //E quais aspectos do Colégio CAVE foram mais importantes para despertar o seu interesse? Podem ser várias!
            trigger: "11",
        },
        {
            id: "11",
            component: <CheckboxInteresse />,
            waitAction: true,
            trigger: "11.1",
            metadata: {
                idPergunta: perguntas[3] ? `${perguntas[3].idPergunta}` : "-",
            },
        },
        {
            id: "11.1",
            message: "Bacana!",
            trigger: "12",
        },
        {
            id: "12",
            message: perguntas[4] ? ` - ${perguntas[4].pergunta}` : '', //Faltou algum que não foi listado? Pode me contar!
            trigger: "13",
        },
        {
            id: "13",
            user: true,
            trigger: "14.1",
            metadata: {
                idPergunta: perguntas[4] ? `${perguntas[4].idPergunta}` : "-",
            },
        },
        {
            id: "14.1",
            message: "Beleza!",
            trigger: "14",
        },
        {
            id: "14",
            message: perguntas[5] ? ` - ${perguntas[5].pergunta}` : '', //Me conte um pouquinho sobre o porquê de escolher o Colégio CAVE esse ano? Qual foi sua motivação para entrar nessa Jornada conosco?
            trigger: "15",
        },
        {
            id: "15",
            user: true,
            trigger: "16.1",
            metadata: {
                idPergunta: perguntas[5] ? `${perguntas[5].idPergunta}` : "-",
            },
        },
        {
            id: "16.1",
            message: "Ok, anotado!",
            trigger: "16",
        },
        {
            id: "16",
            message: perguntas[6] ? ` - ${perguntas[6].pergunta}` : '', // Qual a importância que você enxerga em aulas práticas e no uso de laboratórios para o aprendizado?
            trigger: "17",
        },
        {
            id: "17",
            user: true,
            trigger: "18.1",
            metadata: {
                idPergunta: perguntas[6] ? `${perguntas[6].idPergunta}` : "-",
            },
        },
        {
            id: "18.1",
            message: "No CAVE gostamos de aplicar os conhecimentos das aulas teóricas na prática, por isso temos aulas nos laboratórios de Química, Física, Biologia e Gastronomia. Além de tudo isso, você ainda terá aulas nas oficinas Maker, Trabalhos Manuais, Áudio e Vídeo, Teatro e Música.",
            trigger: "18",
        },
        {
            id: "18",
            message: perguntas[7] ? ` - ${perguntas[7].pergunta}` : '', // Para você, como seria o professor ideal? Quais as qualidades que um professor precisa ter?
            trigger: "19",
        },
        {
            id: "19",
            user: true,
            trigger: "20.1",
            metadata: {
                idPergunta: perguntas[7] ? `${perguntas[7].idPergunta}` : "-",
            },
        },
        {
            id: "20.1",
            message: "Legal! No CAVE temos muito orgulho dos nossos professores. Você vai conhecer alguns na última etapa da Jornada!",
            trigger: "20",
        },
        {
            id: "20",
            message: perguntas[8] ? ` - ${perguntas[8].pergunta}` : '', // Como você gostaria de ser avaliado na escola? Quais aspectos você acredita que são mais importantes de serem observados?
            trigger: "21",
        },
        {
            id: "21",
            user: true,
            trigger: "26",
            metadata: {
                idPergunta: perguntas[8] ? `${perguntas[8].idPergunta}` : "-",
            },
        },
        {
            id: "26.1",
            message: "Que bacana! Agora quero te propor um exercício de imaginação.",
            trigger: "26",
        },
        {
            id: "26",
            message: perguntas[9] ? ` - ${perguntas[9].pergunta}` : '', // Você é diretor da escola por um dia! Isso mesmo, você faz as regras! Quais seriam as três regras mais importantes que você implementaria?
            trigger: "27",
        },
        {
            id: "27",
            user: true,
            trigger: "28",
            metadata: {
                idPergunta: perguntas[9] ? `${perguntas[9].idPergunta}` : "-",
            },
        },
        {
            id: "28",
            message: "Interessante!",
            trigger: "29",
        },
        {
            id: "29",
            message: perguntas[10] ? ` - ${perguntas[10].pergunta}` : '', // E o que suas regras poderiam trazer de positivo para os alunos, em sua opinião?
            trigger: "30",
        },
        {
            id: "30",
            user: true,
            trigger: "32",
            metadata: {
                idPergunta: perguntas[10] ? `${perguntas[10].idPergunta}` : "-",
            },
        },
        {
            id: "32",
            message: perguntas[11] ? ` - ${perguntas[11].pergunta}` : '', // E de negativo?
            trigger: "33",
        },
        {
            id: "33",
            user: true,
            trigger: "34",
            metadata: {
                idPergunta: perguntas[11] ? `${perguntas[11].idPergunta}` : "-",
            },
        },
        {
            id: "34",
            message: "Você ainda é diretor!",
            trigger: "35",
        },
        {
            id: "35",
            message: perguntas[12] ? ` - ${perguntas[12].pergunta}` : '', // Como você avaliaria seus alunos?
            trigger: "36",
        },
        {
            id: "36",
            user: true,
            trigger: "37",
            metadata: {
                idPergunta: perguntas[12] ? `${perguntas[12].idPergunta}` : "-",
            },
        },
        {
            id: "37",
            message: "Muito legal!",
            trigger: "38",
        },
        {
            id: "38",
            message: "Eu adoro conhecer novas ideias, são sempre importantes para construirmos o colégio que queremos!",
            trigger: "39",
        },
        {
            id: "39",
            message: `- ${name}, chegamos ao fim por hoje! Para finalizarmos essa etapa verifique se você respondeu como gostaria e escolha uma das opções abaixo:`,
            trigger: "40",
        },
        {
            id: "40",
            options: [
                { value: "Tudo certo! Enviar minhas informações.", label: "1) Tudo certo! Enviar minhas informações.", trigger: "41" },
                { value: "Reiniciar conversa", label: "2) Reiniciar conversa.", trigger: "1" },
            ],
        },
        {
            id: "41",
            message: "Ótimo! Já registrei tudo.",
            trigger: "42",
        },
        {
            id: "42",
            message: "A próxima etapa da nossa Jornada irá trazer questões para observar suas competências e habilidades aplicadas ao cotidiano, sem privilegiar apenas a memorização ou uma visão conteudista.",
            trigger: "43",
        },
        {
            id: "43",
            message: `Fique atento aos meios de comunicação cadastrados, entraremos em contato para te guiar nessa Jornada de Acolhimento! Até lá!`,
            end: true,
        },
    ];
    const handleEnd = async ({ steps, values }) => {
        // console.log(steps);
        // console.log(values);
        const respostas = steps.filter((item) => {
            return item.metadata !== undefined;
        });
        // console.log(respostas);
        const respostasTratada = respostas.map((item) => {
            return {
                resposta: item.value,
                idPergunta: item.metadata.idPergunta,
            };
        });

        try {
            await api({
                method: "POST",
                url: `answer`,
                data: {
                    respostas: respostasTratada,
                    idUsuarioLogado: idUsuarioLogado,
                    concluiu_etapa_3: true,
                },
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
        } catch (error) {
            alert(error);
        }
    };
    return (
        <div className="container">
            <ThemeProvider theme={theme}>
                <ChatBot hideUserAvatar={true} bubbleStyle={{ fontSize: "14px" }} handleEnd={handleEnd} steps={steps} {...config} />
            </ThemeProvider>
        </div>
    );
}

export default memo(ChatbotEtapa3);
