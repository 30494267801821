import { faList, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import React from "react";
import MsgErroInput from "../../../components/generic/MsgErroInput";
import * as Yup from "yup";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";

export default function FormDadosPessoais() {
    const [idUserLogado, setIdUserLogado] = React.useState(0);
    const [profile, setProfile] = React.useState({
        nome_completo: "",
        nome_gosta_de_ser_chamado: "",
        cpf: "",
        data_nascimento: "",
        email: "",
        celular: "",
        ensino: "",
    });
    const schema = Yup.object().shape({
        nome_completo: Yup.string()
            .min(3, "Muito curto o nome!")
            .required("Campo obrigatório."),
        nome_gosta_de_ser_chamado: Yup.string().required("Campo obrigatório."),
        data_nascimento: Yup.string().required("Campo obrigatório."),
        email: Yup.string()
            .email("E-mail inválido.")
            .required("Campo obrigatório."),
        celular: Yup.string().required("Campo obrigatório."),
    });

    const history = useHistory();
    React.useEffect(() => {
        async function userLoged() {
            try {
                const res = await api({
                    method: "get",
                    url: "me",
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                const resDadosUsuario = await api({
                    method: "get",
                    url: `candidates/${res.data.userIdLogado}`,
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                //00-00-0000
                if (resDadosUsuario.data.data_nascimento === "Invalid date") {
                    resDadosUsuario.data.data_nascimento = "";
                }
                setProfile(resDadosUsuario.data);
                setIdUserLogado(res.data.userIdLogado);
            } catch (error) {
                alert(error.response.data.error);
                history.push("/");
            }
        }
        userLoged();
    }, [history]);

    const handleSubmit = async (value) => {
        document.getElementById("btnSalvar").disabled = true;
        // console.log(value);
        const data = {
            ...value,
            data_nascimento: value.data_nascimento
                .split("-")
                .reverse()
                .join("-"),
        };
        try {
            const res = await api({
                method: "put",
                url: `/candidate/${idUserLogado}`,
                data: data,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            alert(res.data.msg);
            document.getElementById("btnSalvar").disabled = false;
        } catch (error) {
            alert(error.response.data.error);
            document.getElementById("btnSalvar").disabled = false;
        }
    };

    return (
        <div>
            <h3 className="tituloDashBoard">
                <FontAwesomeIcon icon={faList} className="mr-2" />
                Dados Pessoais
            </h3>
            <hr />
            <Formik enableReinitialize initialValues={profile} validationSchema={schema} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label htmlFor="nome_completo">Nome Completo</label>
                                <Field name="nome_completo" className="form-control" />
                                {errors.nome_completo && touched.nome_completo ? (
                                    <MsgErroInput>{errors.nome_completo}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="nome_gosta_de_ser_chamado">Como gostaria de ser chamado?</label>
                                <Field name="nome_gosta_de_ser_chamado" className="form-control" />
                                {errors.nome_gosta_de_ser_chamado && touched.nome_gosta_de_ser_chamado ? (
                                    <MsgErroInput>{errors.nome_gosta_de_ser_chamado}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="cpf">CPF</label>
                                <Field name="cpf" disabled className="form-control" />
                                {errors.cpf && touched.cpf ? <MsgErroInput>{errors.cpf}</MsgErroInput> : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="ensino">Inscrição para qual ensino?</label>
                                <Field
                                    component="select"
                                    name="ensino"
                                    disabled
                                    id="ensino"
                                    multiple={false}
                                    className="form-control"
                                >
                                    <option value="">- SELECIONE -</option>
                                    <option value="9º ANO ENSINO FUNDAMENTAL 2024">9º ANO ENSINO FUNDAMENTAL 2024</option>
                                </Field>
                                {errors.ensino && touched.ensino ? <MsgErroInput>{errors.ensino}</MsgErroInput> : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label htmlFor="data_nascimento">Data Nascimento</label>
                                <Field
                                    name="data_nascimento"
                                    children={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            className="form-control"
                                            mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        />
                                    )}
                                />
                                {errors.data_nascimento && touched.data_nascimento ? (
                                    <MsgErroInput>{errors.data_nascimento}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="email">Email</label>
                                <Field name="email" className="form-control" />
                                {errors.email && touched.email ? <MsgErroInput>{errors.email}</MsgErroInput> : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="celular">Celular</label>
                                <Field
                                    name="celular"
                                    children={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            className="form-control"
                                            mask={[
                                                "(",
                                                /[1-9]/,
                                                /\d/,
                                                ")",
                                                " ",
                                                /\d/,
                                                " ",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                            ]}
                                        />
                                    )}
                                />
                                {errors.celular && touched.celular ? (
                                    <MsgErroInput>{errors.celular}</MsgErroInput>
                                ) : null}
                            </div>
                        </div>

                        <hr className="mb-4" />

                        <div className="btnRodape">
                            <button type="submit" id="btnSalvar" className="btn btn-danger">
                                <FontAwesomeIcon icon={faSave} /> Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
