import React from "react";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";
import GridEtapas from "./GridEtapas";

export default function GridRespostas() {
    const [list1, setList1] = React.useState([]);
    const [list2, setList2] = React.useState([]);
    const [list3, setList3] = React.useState([]);
    const { idUsuario } = useParams();
    React.useEffect(() => {
        async function listQuiz1() {
            const res = await api({
                method: "get",
                url: `/answer/${idUsuario}?filter=ETAPA01`,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            setList1(res.data);
        }
        listQuiz1();

        async function listQuiz2() {
            const res = await api({
                method: "get",
                url: `/answer/${idUsuario}?filter=ETAPA02`,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            setList2(res.data);
        }
        listQuiz2();

        async function listQuiz3() {
            const res = await api({
                method: "get",
                url: `/answer/${idUsuario}?filter=ETAPA03`,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            setList3(res.data);
        }
        listQuiz3();
    }, [idUsuario]);

    return (
        <div className="row">
            <div className="col-sm-12">
                {list1.length > 0 ? (
                    <GridEtapas list={list1} titulo="ETAPA 01"/>
                ): ""}
                {list2.length > 0 ? (
                    <GridEtapas list={list2} titulo="ETAPA 02"/>
                ): ""}
                {list3.length > 0 ? (
                    <GridEtapas list={list3} titulo="ETAPA 03"/>
                ): ""}
            </div>
        </div>
    );
}
