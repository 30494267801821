import React from "react";
import { Container } from "reactstrap";
import GridCandidatos from "./components/gridCandidatos/GridCandidatos";
import Menu from "../../components/menu/Menu";

export default function Candidatos() {
    return (
        <>
            <Menu />
            <div className="App wrapper">
                <Container fluid>
                    <div className="row">
                        <div className="col-sm-12">
                            <GridCandidatos />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
