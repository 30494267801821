import { faCog, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import React from "react";
import MsgErroInput from "../../../components/generic/MsgErroInput";
import * as Yup from "yup";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

export default function FormAlterarSenha() {
    const [idUserLogado, setIdUserLogado] = React.useState(0);
    // eslint-disable-next-line no-unused-vars
    const [profile, setProfile] = React.useState({
        senha_atual: "",
        senha: "",
        confirmar_senha: "",
    });
    const schema = Yup.object().shape({
        senha_atual: Yup.string().required("Informe sua senha atual."),
        senha: Yup.string()
            .required("O campo senha é obrigatório.")
            .min(8, "A senha deve ter pelo menos 8 caracteres"),
        confirmar_senha: Yup.string()
            .required("Campo obrigatório.")
            .oneOf([Yup.ref("senha"), null], "Senha não confere."),
    });

    const history = useHistory();
    React.useEffect(() => {
        async function userLoged() {
            try {
                const res = await api({
                    method: "get",
                    url: "me",
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                setIdUserLogado(res.data.userIdLogado);
            } catch (error) {
                alert(error.response.data.error);
                history.push("/");
            }
        }
        userLoged();
    }, [history]);

    const handleSubmit = async (value) => {
        document.getElementById("btnSalvar").disabled = true;
        try {
            const res = await api({
                method: "put",
                url: `/candidate/alter-password/${idUserLogado}`,
                data: value,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });

            alert(res.data.msg);
            localStorage.removeItem("token");
            localStorage.removeItem("userNameLogged");
            localStorage.removeItem("super_admin");
            document.getElementById("btnSalvar").disabled = false;
            history.push("/");
        } catch (error) {
            alert(error.response.data.error);
            document.getElementById("btnSalvar").disabled = false;
        }
    };
    return (
        <div>
            <h3 className="tituloDashBoard">
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                Alterar Senha
            </h3>
            <hr />
            <Formik enableReinitialize initialValues={profile} validationSchema={schema} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label htmlFor="senha_atual">Senha Atual</label>
                                <Field type="password" name="senha_atual" className="form-control" />
                                {errors.senha_atual && touched.senha_atual ? (
                                    <MsgErroInput>{errors.senha_atual}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="senha">Nova Senha</label>
                                <Field type="password" name="senha" className="form-control" />
                                {errors.senha && touched.senha ? <MsgErroInput>{errors.senha}</MsgErroInput> : null}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="confirmar_senha">Confirmar Nova Senha</label>
                                <Field type="password" name="confirmar_senha" className="form-control" />
                                {errors.confirmar_senha && touched.confirmar_senha ? (
                                    <MsgErroInput>{errors.confirmar_senha}</MsgErroInput>
                                ) : null}
                            </div>
                        </div>

                        <hr className="mb-4" />

                        <div className="btnRodape">
                            <button type="submit" id="btnSalvar" className="btn btn-danger">
                                <FontAwesomeIcon icon={faSave} /> Confirmar
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
