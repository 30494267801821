import React from "react";
import LogoSmall from "../../assets/logo-small.png";

export default function MenuLandingPage() {
    return (
        <header>
            <div className="navbar navbar-dark box-shadow" id="divMenuLandingPage">
                <div className="container d-flex justify-content-between">
                    <a href="https://www.cave.com.br/" title="Site Colégio Cave" className="navbar-brand d-flex align-items-center">
                    <img src={LogoSmall} alt="Logo Cave" />
                    </a>
                </div>
            </div>
        </header>
    );
}
