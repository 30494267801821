import React from "react";
import Menu from "../../components/menu/Menu";
import ConteudoFaq from "./components/ConteudoFaq";

export default function Faq() {
    return (
        <>
            <Menu />
            <div className="App wrapper">
                <ConteudoFaq/>
            </div>
            <footer className="footer">
                <div className="container p-2 text-center">
                    <span className="text-light">Cave 2023 © Todos os direitos reservados.</span>
                </div>
            </footer>
        </>
    );
}
