import React from "react";

export default function GridEtapas({list, titulo}) {
    return (
        <div style={{ overflow: "auto" }}>
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th colSpan="2" className="text-center">
                            -- {titulo} --
                        </th>
                    </tr>
                    <tr>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                    </tr>
                </thead>
                <tbody style={{ overflow: "auto" }}>
                    {list.map((quiz, index) => {
                        return (
                            <tr key={index}>
                                <td>{quiz.pergunta}</td>
                                <td>{quiz.resposta}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
