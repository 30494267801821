import React, { memo } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import api from "../../services/api";
import CheckboxNoticia from "../chatbot/CheckboxNoticia";
// import user from "../../assets/img/user.png";
function ChatbotEtapa2({ perguntas, idUsuarioLogado }) {
    // console.log(perguntas);
    // console.log("etapa2");
    const theme = {
        background: "#F5F8FB",
        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
        headerBgColor: "#f9c647",
        headerFontColor: "#A32927",
        headerFontSize: "16px",
        botBubbleColor: "#A32927",
        botFontColor: "#fff",
        userBubbleColor: "#fff",
        userFontColor: "#4c4c4c",
    };
    const config = {
        width: "100%",
        height: "77vh",
        headerTitle: "HÁBITOS",
        placeholder: "Responda aqui...",
        // userAvatar: `${user}`,
    };
    const name = localStorage.getItem("userNameLogged");

    const steps = [
        {
            id: "1",
            message: `Olá, ${name}! `,
            trigger: "2",
        },
        {
            id: "2",
            message: "Estou de volta e vamos dar início à segunda etapa da nossa Jornada.",
            trigger: "3",
        },
        {
            id: "3",
            message: "Vamos lá!",
            trigger: "4",
        },
        {
            id: "4",
            message: perguntas[0] ? ` - ${perguntas[0].pergunta}` : "", //Quais hábitos fazem você se sentir bem?
            trigger: "5",
        },
        {
            id: "5",
            user: true,
            trigger: "6",
            metadata: {
                idPergunta: perguntas[0] ? `${perguntas[0].idPergunta}` : "-",
            },
        },
        {
            id: "6",
            message: perguntas[1] ? ` - ${perguntas[1].pergunta}` : "", // Você tem hábito de assistir TV?
            trigger: "7",
        },
        {
            id: "7",
            options: [
                { value: "Sim", label: "Sim", trigger: "8" },
                { value: "Não", label: "Não", trigger: "15.1" },
            ],
            metadata: {
                idPergunta: perguntas[1] ? `${perguntas[1].idPergunta}` : "-",
            },
        },
        {
            id: "8",
            message: perguntas[2] ? ` - ${perguntas[2].pergunta}` : "", //O que você costuma assistir?
            trigger: "9",
        },
        {
            id: "9",
            user: true,
            trigger: "9.1",
            metadata: {
                idPergunta: perguntas[2] ? `${perguntas[2].idPergunta}` : "-",
            },
        },
        {
            id: "9.1",
            message: "Bacana!",
            trigger: "12",
        },
        // {
        //     id: "10",
        //     message: perguntas[3] ? ` - ${perguntas[3].pergunta}` : "", //E programas em serviços de streaming?
        //     trigger: "11",
        // },
        // {
        //     id: "11",
        //     options: [
        //         { value: "Sim", label: "Sim", trigger: "12" },
        //         { value: "Não", label: "Não", trigger: "15.1" },
        //     ],
        //     metadata: {
        //         idPergunta: perguntas[3] ? `${perguntas[3].idPergunta}` : "-",
        //     },
        // },
        {
            id: "12",
            message: perguntas[4] ? ` - ${perguntas[4].pergunta}` : "", //O que você assiste normalmente?
            trigger: "13",
        },
        {
            id: "13",
            user: true,
            trigger: "14.1",
            metadata: {
                idPergunta: perguntas[4] ? `${perguntas[4].idPergunta}` : "-",
            },
        },
        {
            id: "14.1",
            message: "Que legal! Eu gosto de maratonar várias séries.",
            trigger: "16",
        },
        {
            id: "15.1",
            message: "Gosta de fazer outras coisas, né? Entendo! ",
            trigger: "16",
        },
        {
            id: "16",
            message: perguntas[5] ? ` - ${perguntas[5].pergunta}` : "", //Você costuma acompanhar as notícias e atualidades? Me conte como você se informa!
            trigger: "17",
        },
        {
            id: "17",
            component: <CheckboxNoticia />,
            waitAction: true,
            trigger: "18",
            metadata: {
                idPergunta: perguntas[5] ? `${perguntas[5].idPergunta}` : "-",
            },
        },
        {
            id: "18",
            message: perguntas[6] ? ` - ${perguntas[6].pergunta}` : "", // Como anda o hábito de leitura? Você gosta de ler?
            trigger: "19.1",
        },
        {
            id: "19.1",
            options: [
                { value: "Sim", label: "Sim", trigger: "18.1" },
                { value: "Não", label: "Não", trigger: "19.2" },
            ],
            metadata: {
                idPergunta: perguntas[6] ? `${perguntas[6].idPergunta}` : "-",
            },
        },
        {
            id: "18.1",
            message: "Legal! Eu também.",
            trigger: "20",
        },
        {
            id: "19.2",
            message: "Mesmo não gostando muito você deve acabar lendo um pouco no dia-a-dia, não é?",
            trigger: "20",
        },
        {
            id: "20",
            message: perguntas[7] ? ` - ${perguntas[7].pergunta}` : "", // Com que frequência você lê?
            trigger: "21",
        },
        {
            id: "21",
            user: true,
            trigger: "22",
            metadata: {
                idPergunta: perguntas[7] ? `${perguntas[7].idPergunta}` : "-",
            },
        },
        {
            id: "22",
            message: "Bacana!",
            trigger: "23",
        },
        {
            id: "23",
            message: perguntas[8] ? ` - ${perguntas[8].pergunta}` : "", // O que você mais lê? Livros, textos, HQs, notícias, tudo vale!
            trigger: "24",
        },
        {
            id: "24",
            user: true,
            trigger: "25",
            metadata: {
                idPergunta: perguntas[8] ? `${perguntas[8].idPergunta}` : "-",
            },
        },
        {
            id: "25",
            message: perguntas[9] ? ` - ${perguntas[9].pergunta}` : "-", //Você pode me contar um pouco dos seus hobbies e habilidades? Pode ser desenho, tocar um instrumento, escrever, dançar, praticar um esporte, teatro, o que você quiser!
            trigger: "26",
        },
        {
            id: "26",
            user: true,
            trigger: "27",
            metadata: {
                idPergunta: perguntas[9] ? `${perguntas[9].idPergunta}` : "-",
            },
        },
        {
            id: "27",
            message: "Ter uma atividade que a gente se dedica faz muito bem. Para aprender e para se sentir bem!",
            trigger: "28",
        },
        {
            id: "28",
            message: perguntas[10] ? ` - ${perguntas[10].pergunta}` : "-", //Atividades físicas fazem parte da sua rotina?
            trigger: "29",
        },
        {
            id: "29",
            options: [
                { value: "Sim", label: "Sim", trigger: "30" },
                { value: "Não", label: "Não", trigger: "30" },
            ],
            metadata: {
                idPergunta: perguntas[10] ? `${perguntas[10].idPergunta}` : "-",
            },
        },
        {
            id: "30",
            message: perguntas[11] ? ` - ${perguntas[11].pergunta}` : "-", // Com que frequência você faz algum tipo de exercício?
            trigger: "31",
        },
        {
            id: "31",
            user: true,
            trigger: "32",
            metadata: {
                idPergunta: perguntas[11] ? `${perguntas[11].idPergunta}` : "-",
            },
        },
        {
            id: "32",
            message: perguntas[12] ? ` - ${perguntas[12].pergunta}` : "-", // Qual tipo de atividade você pratica?
            trigger: "33",
        },
        {
            id: "33",
            user: true,
            trigger: "34",
            metadata: {
                idPergunta: perguntas[12] ? `${perguntas[12].idPergunta}` : "-",
            },
        },
        {
            id: "34",
            message: "Legal!",
            trigger: "35",
        },
        {
            id: "35",
            message: perguntas[13] ? ` - ${perguntas[13].pergunta}` : "-", // Sua família tem o hábito de participar em trabalhos sociais e comunitários?
            trigger: "36",
        },
        {
            id: "36",
            options: [
                { value: "Sim", label: "Sim", trigger: "37" },
                { value: "Não", label: "Não", trigger: "37" },
            ],
            metadata: {
                idPergunta: perguntas[13] ? `${perguntas[13].idPergunta}` : "-",
            },
        },
        {
            id: "37",
            message: perguntas[14] ? ` - ${perguntas[14].pergunta}` : "-",  // Você participa ou já participou de alguma iniciativa social?
            trigger: "38",
        },
        {
            id: "38",
            options: [
                { value: "Sim", label: "Sim", trigger: "39" },
                { value: "Não", label: "Não", trigger: "40" },
            ],
            metadata: {
                idPergunta: perguntas[14] ? `${perguntas[14].idPergunta}` : "-",
            },
        },
        {
            id: "40",
            message: "Ok!",
            trigger: "47",
        },
        {
            id: "39",
            message: perguntas[15] ? ` - ${perguntas[15].pergunta}` : "-", // Que bacana! Você pode me contar um pouquinho sobre?
            trigger: "41",
        },
        {
            id: "41",
            user: true,
            trigger: "47",
            metadata: {
                idPergunta: perguntas[15] ? `${perguntas[15].idPergunta}` : "-",
            },
        },
        // {
        //     id: "42",
        //     message: perguntas[16] ? ` - ${perguntas[16].pergunta}` : "-", // Você já praticou ou pratica a meditação?
        //     trigger: "43",
        // },
        // {
        //     id: "43",
        //     options: [
        //         { value: "Sim", label: "Sim", trigger: "44" },
        //         { value: "Não", label: "Não", trigger: "44" },
        //     ],
        //     metadata: {
        //         idPergunta: perguntas[16] ? `${perguntas[16].idPergunta}` : "-",
        //     },
        // },
        // {
        //     id: "44",
        //     message: perguntas[17] ? ` - ${perguntas[17].pergunta}` : "-",// O que você acha dessa prática?
        //     trigger: "45",
        // },
        // {
        //     id: "45",
        //     user: true,
        //     trigger: "46",
        //     metadata: {
        //         idPergunta: perguntas[17] ? `${perguntas[17].idPergunta}` : "-",
        //     },
        // },
        // {
        //     id: "46",
        //     message: "Gostamos muito de estimular a meditação aqui no CAVE!",
        //     trigger: "47",
        // },
        {
            id: "47",
            message: perguntas[18] ? ` - ${perguntas[18].pergunta}` : "-", // Você joga ou acompanha e-Games (vídeo games, jogos online, etc)?
            trigger: "48",
        },
        {
            id: "48",
            options: [
                { value: "Sim", label: "Sim", trigger: "49" },
                { value: "Não", label: "Não", trigger: "52" },
            ],
            metadata: {
                idPergunta: perguntas[18] ? `${perguntas[18].idPergunta}` : "-",
            },
        },
        {
            id: "49",
            message:
                "Ótimo! Na nossa Semana CAVE fizemos o Enter CAVE, um torneio 100% online de jogos organizados pelos próprios alunos!",
            trigger: "50",
        },
        {
            id: "50",
            message: perguntas[19] ? ` - ${perguntas[19].pergunta}` : "-", // Como os jogos online fazem parte da sua vida?
            trigger: "51",
        },
        {
            id: "51",
            user: true,
            trigger: "53",
            metadata: {
                idPergunta: perguntas[19] ? `${perguntas[19].idPergunta}` : "-",
            },
        },
        {
            id: "52",
            message: "Ok!",
            trigger: "53",
        },
        {
            id: "53",
            message: perguntas[20] ? ` - ${perguntas[20].pergunta}` : "-", // Quais redes sociais você mais utiliza?
            trigger: "54",
        },
        {
            id: "54",
            user: true,
            trigger: "55",
            metadata: {
                idPergunta: perguntas[20] ? `${perguntas[20].idPergunta}` : "-",
            },
        },
        {
            id: "55",
            message: "Legal! O CAVE está no Instagram, Facebook, e Youtube. ",
            trigger: "56",
        },
        {
            id: "56",
            message: perguntas[21] ? ` - ${perguntas[21].pergunta}` : "-", // Qual é o seu @? Quero manter contato com você!
            trigger: "57",
        },
        {
            id: "57",
            user: true,
            trigger: "58",
            metadata: {
                idPergunta: perguntas[21] ? `${perguntas[21].idPergunta}` : "-",
            },
        },
        {
            id: "58",
            message: "Obrigado!",
            trigger: "59",
        },
        {
            id: "59",
            message: `- ${name}, Chegamos ao fim por hoje! Para finalizarmos essa etapa verifique se você respondeu como gostaria e escolha uma das opções abaixo:`,
            trigger: "60",
        },
        {
            id: "60",
            options: [
                {
                    value: "Tudo certo! Enviar minhas informações.",
                    label: "1) Tudo certo! Enviar minhas informações.",
                    trigger: "61",
                },
                { value: "Reiniciar conversa", label: "2) Reiniciar conversa.", trigger: "1" },
            ],
        },
        {
            id: "61",
            message: `Beleza! Já registrei tudo. Prossiga para a terceira etapa. Até lá!`,
            end: true,
        },
    ];
    const handleEnd = async ({ steps, values }) => {
        // console.log(steps);
        // console.log(values);
        const respostas = steps.filter((item) => {
            return item.metadata !== undefined;
        });
        // console.log(respostas);
        const respostasTratada = respostas.map((item) => {
            return {
                resposta: item.value,
                idPergunta: item.metadata.idPergunta,
            };
        });

        try {
            await api({
                method: "POST",
                url: `answer`,
                data: {
                    respostas: respostasTratada,
                    idUsuarioLogado: idUsuarioLogado,
                    concluiu_etapa_2: true,
                },
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
        } catch (error) {
            alert(error);
        }
    };
    return (
        <div className="container">
            <ThemeProvider theme={theme}>
                <ChatBot
                    hideUserAvatar={true}
                    bubbleStyle={{ fontSize: "14px" }}
                    handleEnd={handleEnd}
                    steps={steps}
                    {...config}
                />
            </ThemeProvider>
        </div>
    );
}

export default memo(ChatbotEtapa2);
