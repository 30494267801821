import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import MsgErroInput from "../../components/generic/MsgErroInput";
import api from "../../services/api";
import LoadingOverlay from "react-loading-overlay";
import banner from "../../assets/banner_login.jpg";
import { Link } from "react-router-dom";

export default function EsqueciSenha(props) {
    const [loading, setLoading] = React.useState(false);
    const camposLogin = {
        email: "",
    };
    const schema = Yup.object().shape({
        email: Yup.string()
            .email("E-mail inválido.")
            .required("Campo obrigatório."),
    });
    const handleSubmit = async ({ email }) => {
        setLoading(true);
        try {
            const res = await api({
                method: "post",
                url: "forget-password",
                data: { email },
            });
            setLoading(false);
            alert(res.data.msg);
            props.history.push("/");
        } catch (error) {
            setLoading(false);
            if (!error.response) {
                // network error
                alert("Erro ao recuperar a senha. Tente novamente mais tarde.");
            } else {
                alert(error.response.data.error);
            }
        }
    };
    return (
        <LoadingOverlay active={loading} spinner text="Carregando...">
            <div className="caixaLogin">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div
                                            className="col-lg-6 d-none d-lg-block bg-login-image"
                                            style={{
                                                background: `url(${banner})`,
                                            }}
                                        ></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Esqueci minha senha</h1>
                                                </div>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={camposLogin}
                                                    validationSchema={schema}
                                                    onSubmit={handleSubmit}
                                                    className="user"
                                                >
                                                    {({ errors, touched }) => (
                                                        <Form>
                                                            <div className="form-group">
                                                                <Field
                                                                    name="email"
                                                                    type="email"
                                                                    placeholder="Informe seu e-mail"
                                                                    className="form-control"
                                                                />
                                                                {errors.email && touched.email ? (
                                                                    <MsgErroInput>{errors.email}</MsgErroInput>
                                                                ) : null}
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-danger btn-user btn-block"
                                                            >
                                                                Recuperar Senha
                                                            </button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                                <hr />
                                                <div className="text-right">
                                                    <Link to="/venha-para-o-cave" style={{ color: "#dc3545" }}>
                                                        Quero me inscrever
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
