import React from "react";
import { Field, Form, Formik } from "formik";
import MsgErroInput from "../../../components/generic/MsgErroInput";
import * as Yup from "yup";
import api from "../../../services/api";
import MaskedInput from "react-text-mask";
import { useHistory } from "react-router-dom";

export default function FormInscricao() {
    const profile = {
        nome_completo: "",
        nome_gosta_de_ser_chamado: "",
        ensino: "",
        data_nascimento: "",
        email: "",
        celular: "",
        cpf: "",
        senha: "",
        confirmar_senha: "",
        formaContato: "",
    };
    const schema = Yup.object().shape({
        nome_completo: Yup.string()
            .min(3, "Muito curto o nome!")
            .required("Campo obrigatório."),
        nome_gosta_de_ser_chamado: Yup.string().required("Campo obrigatório."),
        ensino: Yup.string().required("Campo obrigatório."),
        data_nascimento: Yup.string().required("Campo obrigatório."),
        email: Yup.string()
            .email("E-mail inválido.")
            .required("Campo obrigatório."),
        celular: Yup.string().required("Campo obrigatório."),
        cpf: Yup.string().required("Campo obrigatório."),
        senha: Yup.string()
            .required("O campo senha é obrigatório.")
            .min(8, "A senha deve ter pelo menos 8 caracteres"),
        confirmar_senha: Yup.string()
            .required("Campo obrigatório.")
            .oneOf([Yup.ref("senha"), null], "Senha não confere."),
        formaContato: Yup.string().required("Escolha uma das opções."),
    });

    const history = useHistory();
    const handleSubmit = async (value, { resetForm }) => {
        document.getElementById("btnSalvar").disabled = true;
        const data = {
            ...value,
            data_nascimento: value.data_nascimento
                .split("-")
                .reverse()
                .join("-"),
        };
        try {
            const res = await api({
                method: "POST",
                url: `/candidate`,
                data: data,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            alert(res.data.msg);
            resetForm({});
            document.getElementById("btnSalvar").disabled = false;
            history.push("/");
        } catch (error) {
            alert(error.response.data.error);
            document.getElementById("btnSalvar").disabled = false;
        }
    };

    return (
        <div>
            <Formik enableReinitialize initialValues={profile} validationSchema={schema} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                    <Form>
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <label htmlFor="nome_completo">Nome completo do aluno</label>
                                <Field name="nome_completo" id="nome_completo" className="form-control" />
                                {errors.nome_completo && touched.nome_completo ? (
                                    <MsgErroInput>{errors.nome_completo}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="nome_gosta_de_ser_chamado">Como gostaria de ser chamado?</label>
                                <Field
                                    name="nome_gosta_de_ser_chamado"
                                    id="nome_gosta_de_ser_chamado"
                                    className="form-control"
                                />
                                {errors.nome_gosta_de_ser_chamado && touched.nome_gosta_de_ser_chamado ? (
                                    <MsgErroInput>{errors.nome_gosta_de_ser_chamado}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="ensino">Inscrição para qual ano?</label>
                                <Field
                                    component="select"
                                    name="ensino"
                                    id="ensino"
                                    multiple={false}
                                    className="form-control"
                                >
                                    <option value="">- SELECIONE -</option>
                                    <option value="9º ANO ENSINO FUNDAMENTAL 2024">9º ANO ENSINO FUNDAMENTAL 2024</option>
                                </Field>
                                {errors.ensino && touched.ensino ? <MsgErroInput>{errors.ensino}</MsgErroInput> : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <label htmlFor="data_nascimento">Data de nascimento</label>
                                <Field
                                    name="data_nascimento"
                                    children={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            id="data_nascimento"
                                            className="form-control"
                                            mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        />
                                    )}
                                />
                                {errors.data_nascimento && touched.data_nascimento ? (
                                    <MsgErroInput>{errors.data_nascimento}</MsgErroInput>
                                ) : null}
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="email">E-mail do aluno</label>
                                <Field type="email" name="email" id="email" className="form-control"/>
                                {errors.email && touched.email ? <MsgErroInput>{errors.email}</MsgErroInput> : null}
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="celular">Celular/Whatsapp do aluno</label>
                                <Field
                                    name="celular"
                                    children={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            id="celular"
                                            className="form-control"
                                            mask={[
                                                "(",
                                                /[1-9]/,
                                                /\d/,
                                                ")",
                                                " ",
                                                /\d/,
                                                " ",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                            ]}
                                        />
                                    )}
                                />
                                {errors.celular && touched.celular ? (
                                    <MsgErroInput>{errors.celular}</MsgErroInput>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <label htmlFor="cpf">CPF do aluno</label>
                                <Field
                                    name="cpf"
                                    children={({ field }) => (
                                        <MaskedInput
                                            {...field}
                                            id="cpf"
                                            className="form-control"
                                            mask={[
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ".",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ".",
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                "-",
                                                /\d/,
                                                /\d/,
                                            ]}
                                        />
                                    )}
                                />
                                {errors.cpf && touched.cpf ? <MsgErroInput>{errors.cpf}</MsgErroInput> : null}
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="senha">Defina uma senha</label>
                                <Field
                                    type="password"
                                    name="senha"
                                    id="senha"
                                    className="form-control"
                                />
                                {errors.senha && touched.senha ? <MsgErroInput>{errors.senha}</MsgErroInput> : null}
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="confirmar_senha">Confirme a senha</label>
                                <Field
                                    type="password"
                                    name="confirmar_senha"
                                    id="confirmar_senha"
                                    className="form-control"
                                />
                                {errors.confirmar_senha && touched.confirmar_senha ? (
                                    <MsgErroInput>{errors.confirmar_senha}</MsgErroInput>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-12">
                                Qual a melhor maneira de entrarmos em contato com você?
                                {errors.formaContato && touched.formaContato ? (
                                    <MsgErroInput>{errors.formaContato}</MsgErroInput>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-12">
                                <div className="form-check form-check-inline">
                                    <Field
                                        type="radio"
                                        className="form-check-input"
                                        name="formaContato"
                                        value="E-mail"
                                        id="formaContatoEmail"
                                    />
                                    <label htmlFor="formaContatoEmail" className="form-check-label">
                                        E-mail
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <Field
                                        type="radio"
                                        className="form-check-input"
                                        name="formaContato"
                                        value="Whatsapp"
                                        id="formaContatoWhatsapp"
                                    />
                                    <label htmlFor="formaContatoWhatsapp" className="form-check-label">
                                        Whatsapp
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <button type="submit" id="btnSalvar" className="btn btn-danger">
                                Inscrever
                            </button>
                        </div>
                        {/* <div className="text-right">
                            <h6 style={{color:'red', textAlign:'left', marginTop:'15px'}}>obs.: Encerradas as inscrições para o 2º e 3º Ano Do Ensino Médio 2021</h6>
                        </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    );
}
