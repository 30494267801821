import React from "react";

export default function ConteudoFaq() {
    return (
        <main role="main">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 faq">
                        <h1 className="text-center">Perguntas frequentes</h1>
                        <h6 className="alert alert-danger">1. Como posso me inscrever na Jornada de Acolhimento?</h6>
                        <p>A inscrição deve ser feita diretamente pelo https://colegiocave.com.br/</p>
                        <h6 className="alert alert-danger">2. Quem pode participar da Jornada de Acolhimento?</h6>
                        <p>
                            Estudantes que estão no 8º ano do Ensino Fundamental 2, em 2023
                        </p>
                        <h6 className="alert alert-danger">
                            3. A Jornada de Acolhimento acontecerá de forma presencial?
                        </h6>
                        <p>A Jornada terá 2 fases que acontecerão online e, uma última etapa, que acontecerá presencialmente.</p>
                        <h6 className="alert alert-danger">4. Qual será o funcionamento da Jornada de Acolhimento?</h6>
                        <p>
                            A Jornada de Acolhimento se dá através de quatro partes principais, com o objetivo de conhecer os estudantes. As etapas consistem em:
                        </p>
                        <p>
                            1 - Visita ao Colégio CAVE, com a coordenação do Ensino Fundamental
                        </p>
                        <p>
                            2 - Queremos te conhecer! (questionário online para conhecermos o perfil do estudante)
                        </p>
                        <p>
                            3 - Queremos te perceber! (Questionário online para compreendermos a interpretação de texto, raciocínio lógico e pensamento crítico)
                        </p>
                        <p>
                            4 - Queremos você aqui (etapa presencial, no dia 28 de outubro, para todos os participantes que completaram as 3 etapas anteriores).
                        </p>
                        <h6 className="alert alert-danger">
                            5. É obrigatório participar de todas as fases da Jornada?
                        </h6>
                        <p>
                            Sim, a participação nas quatro fases previstas no edital é necessária para efetivar a participação do aluno, e seu não cumprimento resulta em sua desclassificação do processo de admissão.
                        </p>
                        <h6 className="alert alert-danger">6. Até que data posso participar da Jornada?</h6>
                        <p>
                            É necessário que o aluno tenha realizado as três etapas da Jornada de Acolhimento até 25 de outubro.
                        </p>
                        <h6 className="alert alert-danger">
                            7. Meus responsáveis precisam participar do processo comigo?
                        </h6>
                        <p>
                            Sim, é indicado que a família do aluno participe da Jornada de Acolhimento junto dele.
                            Embora as respostas dadas e responsabilidade sejam do aluno, as questões levantadas em cada
                            etapa abordam aspectos que visam estimular a interação de toda a família, o que é valorizado
                            pelo Colégio CAVE.
                        </p>
                        <h6 className="alert alert-danger">8. Quando serei informado sobre o resultado da Jornada?</h6>
                        <p>
                            Os participantes serão comunicados sobre o resultado em 13 de novembro de 2023, data a partir da qual será possível realizar a matrícula. Os contemplados terão a garantia da vaga até o dia 20 de novembro de 2023.
                        </p>
                        <h6 className="alert alert-danger">
                            9. A participação na Jornada de Acolhimento garante minha admissão para o Colégio?
                        </h6>
                        <p>
                            Não, o número de alunos admitidos será em função do número de vagas disponibilizadas para o 9º ano.
                        </p>
                        <h6 className="alert alert-danger">
                            10. Não fui admitido em 16 de novembro, há uma lista de espera?
                        </h6>
                        <p>
                            Sim, a lista de excedentes estará disponível a partir do dia 21 de novembro de 2023.
                        </p>
                        <h6 className="alert alert-danger">
                            11. Serão concedidas bolsas de estudo integrais ou parciais?
                        </h6>
                        <p>
                            Bolsas atendendo a critérios socioeconômicos serão concedidas após análise, conforme o edital a ser publicado em 03 de outubro de 2023.
                        </p>
                    </div>
                </div>
            </div>
        </main>
    );
}
