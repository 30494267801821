import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../services/api";

export default function GridQuestionarios() {
    const [list, setList] = React.useState([]);
    React.useEffect(() => {
        async function listQuiz() {
            const res = await api({
                method: "get",
                url: "/quiz",
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            setList(res.data.data);
        }
        listQuiz();
    }, []);

    const handleAcao = async (idQuiz, newStatus) => {
        try {
            const res = await api({
                method: "put",
                url: `/quiz/${idQuiz}`,
                data: {
                    newStatus: newStatus,
                },
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            const newList = list.map((quiz) => {
                if (quiz.idQuestionario === idQuiz) {
                    quiz.status = newStatus;
                }
                return quiz;
            });
            setList(newList);
            alert(res.data.msg);
        } catch (error) {
            alert("Erro: " + error.response.data.error);
        }
    };
    return (
        <div className="row">
            <div className="col-sm-12">
                <h3 className="tituloDashBoard">
                    <FontAwesomeIcon icon={faList} className="mr-2" />
                    Etapas
                </h3>
                <hr />
                <div style={{ overflow: "auto" }}>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th>Questionário</th>
                                <th>Status</th>
                                <th className="text-center">Ação</th>
                            </tr>
                        </thead>
                        <tbody style={{ overflow: "auto" }}>
                            {list.map((quiz) => {
                                return (
                                    <tr key={quiz.idQuestionario}>
                                        <td>{quiz.nome}</td>
                                        <td>
                                            <h5>
                                                {quiz.status === "INATIVO" ? (
                                                    <span className="badge badge-danger">INATIVO</span>
                                                ) : (
                                                    <span className="badge badge-success">ATIVO</span>
                                                )}
                                            </h5>
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group mr-2" role="group" aria-label="First group">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        handleAcao(quiz.idQuestionario, "ATIVO");
                                                    }}
                                                    className="btn btn-dark"
                                                >
                                                    ATIVAR
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        handleAcao(quiz.idQuestionario, "INATIVO");
                                                    }}
                                                    className="btn btn-success"
                                                >
                                                    INATIVAR
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
