import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Menu from "../../components/menu/Menu";
import api from "../../services/api";
import GridRespostas from "./components/gridRespostas/GridRespostas";

export default function Respostas() {
    const { idUsuario } = useParams();
    const [dadosBasicos, setDadosBasicos] = React.useState({});
    React.useEffect(() => {
        async function retornarDadosBasicos() {
            const res = await api({
                method: "get",
                url: `/candidates/${idUsuario}`,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            setDadosBasicos({
                celular: res.data.celular,
                cpf: res.data.cpf,
                data_nascimento: res.data.data_nascimento,
                email: res.data.email,
                ensino: res.data.ensino,
                nome_completo: res.data.nome_completo,
                nome_gosta_de_ser_chamado: res.data.nome_gosta_de_ser_chamado,
                formaContato: res.data.formaContato,
            });
        }
        retornarDadosBasicos();
    }, [idUsuario]);
    return (
        <>
            <Menu />
            <div className="App wrapper">
                <Container>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h4>DADOS PESSOAIS</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <b>Nome completo: </b>
                            {dadosBasicos.nome_completo}
                        </div>
                        <div className="col-sm-4">
                            <b>Cpf: </b>
                            {dadosBasicos.cpf}
                        </div>
                        <div className="col-sm-4">
                            <b>Data de Nascimento: </b>
                            {dadosBasicos.data_nascimento}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <b>Email: </b>
                            {dadosBasicos.email}
                        </div>
                        <div className="col-sm-4">
                            <b>Whatsapp:</b> {dadosBasicos.celular}
                        </div>
                        <div className="col-sm-4">
                            <b>Forma de contato: </b>
                            {dadosBasicos.formaContato}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <b>Inscrição para qual ano? </b>
                            {dadosBasicos.ensino}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <b>Como gostaria de ser chamado?</b> {dadosBasicos.nome_gosta_de_ser_chamado}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <GridRespostas />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
