import React from "react";
import { Link, useHistory } from "react-router-dom";
import Menu from "../../components/menu/Menu";
// import api from "../../services/api";

export default function ComoFunciona() {
    const [statusPagamento, setStatusPagamento] = React.useState("PENDENTE");
    const history = useHistory();
    React.useEffect(() => {
        document.getElementById("descricao").value = `CPF do Aluno: ${localStorage.getItem(
            "cpf"
        )} E-mail do aluno: ${localStorage.getItem("email")}`;
        document.getElementById("id_transacao").value = `JORNADA#${localStorage.getItem(
            "idUsuario"
        )}#${localStorage.getItem("cpf")}`;
    }, []);
    React.useEffect(() => {
        async function userLoged() {
            try {
                // const res = await api({
                //     method: "get",
                //     url: "me",
                //     headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                // });
                // setStatusPagamento(res.data.statusPagamento);
                setStatusPagamento('PAGO');
            } catch (error) {
                alert(error.response.data.error);
                history.push("/");
            }
        }
        userLoged();
    }, [history]);
    return (
        <>
            <Menu />
            <div className="App wrapper">
                <main role="main">
                    <div className="jumbotron">
                        <div className="container">
                            <h1 className="display-3">Bem Vindo à Jornada de Acolhimento do Colégio CAVE!</h1>
                            <p>Venha fazer parte de nossa história.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="titulosPrincipais">Apresentação</h2>
                                <p>Olá,</p>
                                <p>
                                    Ficamos muito contentes em ter você aqui. Ao participar da Jornada de Acolhimento do Colégio CAVE para os novos alunos do 9º ano de 2024, esperamos que você se divirta!
                                </p>
                                <p>
                                    Esse processo de acolhimento, do qual você fará parte, reflete a essência de nossa filosofia educacional e nossos objetivos fundamentais: preparar nossos alunos para enfrentar os desafios da vida, reconhecendo que o conhecimento é uma das chaves para alcançar a autonomia, responsabilidade e felicidade.
                                </p>
                                <p>
                                Aqui, na 2º fase do nosso processo, <b>queremos te conhecer!</b>
                                </p>
                                <p>
                                    <b>Para começar, clique no menu acima, em Etapa 1.</b>
                                </p>
                                <p>
                                    <b>Estamos ansiosos para recebê-lo e compartilhar o caminho para a felicidade e o sucesso. Seja parte do CAVE e trilhe o caminho da realização!</b>
                                </p>
                                <p>Após concluir as três etapas dessa fase, entraremos em contato com você, para seguirmos para a nova fase da Jornada de Acolhimento 2024</p>
                                <p>Um abraço e até breve!</p>
                                <p>Time de Acolhimento do Colégio CAVE</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="titulosPrincipais" style={{ marginTop: "2rem" }}>Perguntas Frequentes (FAQ)</h2>
                                <p>Acesse a seção de Perguntas Frequentes da Jornada de Acolhimento! <Link to={"/duvidas"}>ACESSAR</Link></p>
                            </div>
                        </div>
                        {statusPagamento === "PENDENTE" ? (
                            <div className="row" style={{ marginBottom: "30px" }}>
                                <div className="col-md-12">
                                    <h2 className="titulosPrincipais" style={{ marginTop: "2rem" }}>
                                        Inscrição
                                    </h2>
                                    <p>
                                        A inscrição terá um valor de R$ 20,00 (vinte reais). E deverá ser feita clicando
                                        no botão abaixo:
                                    </p>
                                    <form method="post" action="https://www.moip.com.br/PagamentoSimples.do">
                                        <input type="hidden" name="id_carteira" value="alex@cave.com.br" />
                                        <input type="hidden" name="valor" value="2000" />
                                        <input type="hidden" name="nome" value="JORNADA DE ACOLHIMENTO DO CAVE" />
                                        <input type="hidden" name="descricao" id="descricao" value="" />
                                        <input type="hidden" name="id_transacao" id="id_transacao" value="" />
                                        <input
                                            type="image"
                                            name="submit"
                                            src="https://static.wirecard.com.br/imgs/buttons/bt_pagar_c01_e04.png"
                                            alt="Pagar"
                                            border="0"
                                        />
                                    </form>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </main>
            </div>
            <footer className="footer">
                <div className="container p-2 text-center">
                    <span className="text-light">Cave 2023 © Todos os direitos reservados.</span>
                </div>
            </footer>
        </>
    );
}
