import React from "react";

export default function MsgErroInput(props) {
  return (
    <div
      style={{
        color: "#dc3545",
        fontSize: "80%",
      }}
    >
      {props.children}
    </div>
  );
}
