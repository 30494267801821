import React from "react";
import { Container } from "reactstrap";
import Menu from "../../components/menu/Menu";
import FormDadosPessoais from "./components/FormDadosPessoais";

export default function DadosPessoais() {
    return (
        <>
            <Menu />
            <div className="App wrapper">
                <Container>
                    <div className="row">
                        <div className="col-sm-12">
                            <FormDadosPessoais />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
}
