import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope, faList } from "@fortawesome/free-solid-svg-icons";
import api from "../../../../services/api";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function GridCandidatos() {
    const [listUsers, setListUsers] = React.useState([]);
    const [totalUsers, setTotalUsers] = React.useState(0);
    React.useEffect(() => {
        async function listUsers() {
            const res = await api({
                method: "get",
                url: "/candidates",
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            setListUsers(res.data.data);
            setTotalUsers(res.data.total);
        }
        listUsers();
    }, []);
    // const handleChangeStatusPagamento = async (id, newStatus) => {
    //     try {
    //         await api({
    //             method: "put",
    //             url: `/candidate/status-pagamento/${id}`,
    //             data: { status_pagamento: newStatus },
    //             headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
    //         });
    //     } catch (error) {
    //         alert("Erro ao mudar o status." + error);
    //     }
    //     const newUsers = listUsers.map((user) => {
    //         if (user.idUsuario === parseInt(id, 10)) {
    //             user.status_pagamento = newStatus;
    //         }
    //         return user;
    //     });
    //     setListUsers(newUsers);
    // };

    const handleChangeStatusEmail = async (id, newStatus) => {
        let all = document.querySelectorAll(".enviarEmail");
        for (let i = 0, len = all.length; i < len; i++) {
            all[i].disabled = true;
        }
        try {
            const res = await api({
                method: "post",
                url: `/candidate/send-email/${id}`,
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
            alert(res.data.msg);
            const newUsers = listUsers.map((user) => {
                if (user.idUsuario === parseInt(id, 10)) {
                    user.status_envio_email = newStatus;
                }
                return user;
            });
            for (let i = 0, len = all.length; i < len; i++) {
                all[i].disabled = false;
            }
            setListUsers(newUsers);
        } catch (error) {
            for (let i = 0, len = all.length; i < len; i++) {
                all[i].disabled = false;
            }
            alert(error);
        }
    };
    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <h3 className="tituloDashBoard">
                        <FontAwesomeIcon icon={faList} className="mr-2" />
                        Listagem de Candidatos Inscritos - Total ({totalUsers})
                    </h3>
                    <hr />
                    <div>
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    {/* <th>CPF</th>
                                    <th>Email</th> */}
                                    <th>Whatsapp</th>
                                    <th>Ensino</th>
                                    <th>Etapa 1</th>
                                    <th>Etapa 2</th>
                                    <th>Etapa 3</th>
                                    <th>Ficha</th>
                                    <th>Contato</th>
                                    <th>E-mail</th>
                                    {/* <th>Pagto.</th>
                                    <th>WIRECARD</th>
                                    <th className="text-center">Realizou Pagto.</th> */}
                                    <th className="text-center">Enviar E-mail</th>
                                </tr>
                            </thead>
                            <tbody style={{ overflow: "auto" }}>
                                {listUsers.map((user) => {
                                    let etapa1 = "";
                                    let etapa2 = "";
                                    let etapa3 = "";
                                    etapa1 =
                                        user.concluiu_etapa_1 === 0 ? (
                                            "-"
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="2x"
                                                color="#28a745"
                                                className="mr-2"
                                                title="Feito"
                                            />
                                        );
                                    etapa2 =
                                        user.concluiu_etapa_2 === 0 ? (
                                            "-"
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="2x"
                                                color="#28a745"
                                                className="mr-2"
                                                title="Feito"
                                            />
                                        );
                                    etapa3 =
                                        user.concluiu_etapa_3 === 0 ? (
                                            "-"
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="2x"
                                                color="#28a745"
                                                className="mr-2"
                                                title="Feito"
                                            />
                                        );
                                    return (
                                        <tr key={user.idUsuario}>
                                            <td>{user.nome_completo}</td>
                                            {/* <td>{user.cpf}</td>
                                            <td>{user.email}</td> */}
                                            <td>{user.celular}</td>
                                            <td>
                                                {user.ensino === "1º Ano Do Ensino Médio 2021"
                                                    ? "1º Ano"
                                                    : user.ensino === "2º Ano Do Ensino Médio 2021"
                                                    ? "2º Ano"
                                                    : user.ensino === "3º Ano Do Ensino Médio 2021"
                                                    ? "3º Ano"
                                                    : "9º ANO ENSINO FUNDAMENTAL 2024"}
                                            </td>
                                            <td className="text-center">{etapa1}</td>
                                            <td className="text-center">{etapa2}</td>
                                            <td className="text-center">{etapa3}</td>
                                            <td>
                                                <Link to={`/respostas/${user.idUsuario}`}>Respostas</Link>
                                            </td>
                                            <td className="text-center">
                                                {user.formaContato === "Whatsapp" ? (
                                                    <FontAwesomeIcon
                                                        icon={faWhatsapp}
                                                        size="2x"
                                                        color="#28a745"
                                                        className="mr-2"
                                                        title="Whatsapp"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        size="2x"
                                                        icon={faEnvelope}
                                                        className="mr-2"
                                                        color="#343a40"
                                                        title="E-mail"
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <h5>
                                                    {user.status_envio_email === "NÃO ENVIADO" ? (
                                                        <span className="badge badge-danger">NÃO ENVIADO</span>
                                                    ) : (
                                                        <span className="badge badge-success">ENVIADO</span>
                                                    )}
                                                </h5>
                                            </td>
                                            {/* <td>
                                                <h5>
                                                    {user.status_pagamento === "PENDENTE" ? (
                                                        <span className="badge badge-danger">PENDENTE</span>
                                                    ) : (
                                                        <span className="badge badge-success">PAGO</span>
                                                    )}
                                                </h5>
                                            </td>
                                            <td>
                                                jornada#{user.idUsuario}#{user.cpf}
                                            </td>
                                            <td className="text-center">
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-dark"
                                                        onClick={() => {
                                                            handleChangeStatusPagamento(user.idUsuario, "PENDENTE");
                                                        }}
                                                    >
                                                        NÃO
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={() => {
                                                            handleChangeStatusPagamento(user.idUsuario, "PAGO");
                                                        }}
                                                    >
                                                        SIM
                                                    </button>
                                                </div>
                                            </td> */}
                                            <td className="text-center">
                                                <div className="btn-group mr-2" role="group" aria-label="First group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success enviarEmail"
                                                        onClick={() => {
                                                            handleChangeStatusEmail(user.idUsuario, "ENVIADO");
                                                        }}
                                                    >
                                                        ENVIAR
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
