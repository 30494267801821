import axios from "axios";

let url = "https://impactosolucoesweb.com.br:3334";
// if (window.location.hostname.includes("localhost")) {
//     url = "http://localhost:3334";
// }

const api = axios.create({
    baseURL: url,
});

export default api;
