import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import MsgErroInput from "../../components/generic/MsgErroInput";
import api from "../../services/api";
import LoadingOverlay from "react-loading-overlay";
import banner from "../../assets/banner_login.jpg";
import { Link } from "react-router-dom";

export default function RedefinirSenha(props) {
    const [loading, setLoading] = React.useState(false);
    const query = new URLSearchParams(props.location.search); //pegar o token da URL http://localhost:3000/redefinir-senha?token=xxxxx
    const camposLogin = {
        nova_senha: "",
        token: query.get('token'),
        confirmar_nova_senha: "",
    };
    const schema = Yup.object().shape({
        nova_senha: Yup.string()
            .required("O campo senha é obrigatório.")
            .min(8, "A senha deve ter pelo menos 8 caracteres"),
        confirmar_nova_senha: Yup.string()
            .required("Campo obrigatório.")
            .oneOf([Yup.ref("nova_senha"), null], "Senha não confere."),
    });
    const handleSubmit = async ({ nova_senha, confirmar_nova_senha, token }) => {
        setLoading(true);
        try {
            const res = await api({
                method: "post",
                url: "reset-password",
                data: { nova_senha, confirmar_nova_senha, token },
            });
            setLoading(false);
            alert(res.data.msg);
            props.history.push("/");
        } catch (error) {
            setLoading(false);
            if (!error.response) {
                // network error
                alert("Erro ao recuperar a senha. Tente novamente mais tarde.");
            } else {
                alert(error.response.data.error);
            }
        }
    };
    return (
        <LoadingOverlay active={loading} spinner text="Carregando...">
            <div className="caixaLogin">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div
                                            className="col-lg-6 d-none d-lg-block bg-login-image"
                                            style={{
                                                background: `url(${banner})`,
                                            }}
                                        ></div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Redefinir Senha</h1>
                                                </div>
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={camposLogin}
                                                    validationSchema={schema}
                                                    onSubmit={handleSubmit}
                                                    className="user"
                                                >
                                                    {({ errors, touched }) => (
                                                        <Form>
                                                            <Field type="hidden" name="token" />
                                                            <div className="form-group">
                                                                <Field 
                                                                    type="password"
                                                                    name="nova_senha"
                                                                    placeholder="Informe sua nova senha"
                                                                    className="form-control"
                                                                />
                                                                {errors.nova_senha && touched.nova_senha ? (
                                                                    <MsgErroInput>{errors.nova_senha}</MsgErroInput>
                                                                ) : null}
                                                            </div>
                                                            <div className="form-group">
                                                                <Field
                                                                    type="password"
                                                                    name="confirmar_nova_senha"
                                                                    placeholder="Confirmar sua nova senha"
                                                                    className="form-control"
                                                                />
                                                                {errors.confirmar_nova_senha &&
                                                                touched.confirmar_nova_senha ? (
                                                                    <MsgErroInput>
                                                                        {errors.confirmar_nova_senha}
                                                                    </MsgErroInput>
                                                                ) : null}
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-danger btn-user btn-block"
                                                            >
                                                                Alterar Senha
                                                            </button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                                <hr />
                                                <div className="text-right">
                                                    <Link to="/venha-para-o-cave" style={{ color: "#dc3545" }}>
                                                        Quero me inscrever
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
