import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDoorOpen,
    faUsers,
    faQuestion,
    faBook,
    // faCheck,
    faCog,
    faList,
    faQuestionCircle,
    faComment,
} from "@fortawesome/free-solid-svg-icons";
import LogoSmall from "../../assets/logo-small.png";
// import api from "../../services/api";

export default function Menu() {
    const [statusPagamento, setStatusPagamento] = React.useState("PAGO");
    const history = useHistory();
    const handleExit = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userNameLogged");
        localStorage.removeItem("super_admin");
        localStorage.removeItem("cpf");
        localStorage.removeItem("email");
        localStorage.removeItem("idUsuario");
        history.push("/");
    };
    React.useEffect(() => {
        async function userLoged() {
            try {
                // const res = await api({
                //     method: "get",
                //     url: "me",
                //     headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                // });
                // setStatusPagamento(res.data.statusPagamento);
                setStatusPagamento('PAGO');
            } catch (error) {
                alert(error.response.data.error);
                history.push("/");
            }
        }
        userLoged();
    }, [history]);
    return (
        <nav id="divMenu" className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container d-flex justify-content-between">
                <div className="navbar-brand" style={{ paddingTop: 0 }}>
                    <img src={LogoSmall} title="Logo Cave" alt="Logo Cave" />
                </div>
                <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExampleDefault"
                    aria-controls="navbarsExampleDefault"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="navbar-collapse collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav mr-auto">
                        {localStorage.getItem("super_admin") === "1" ? (
                            <>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            window.document.location.pathname === "/candidatos"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to={"/candidatos"}
                                    >
                                        <FontAwesomeIcon icon={faUsers} className="mr-2" />
                                        Candidatos
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            window.document.location.pathname === "/questionarios"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to={"/questionarios"}
                                    >
                                        <FontAwesomeIcon icon={faQuestion} className="mr-2" />
                                        Etapas (Status)
                                    </Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="http://example.com"
                                        id="dropdown01"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                        Resultado
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdown01">
                                        <Link className="dropdown-item" to={"#"}>
                                            Etapa 1/3
                                        </Link>
                                        <Link className="dropdown-item" to={"#"}>
                                            Etapa 2/3
                                        </Link>
                                        <Link className="dropdown-item" to={"#"}>
                                            Etapa 3/3
                                        </Link>
                                    </div>
                                </li> */}
                            </>
                        ) : (
                            ""
                        )}
                        {localStorage.getItem("super_admin") === "0" ? (
                            <>
                                <li className="nav-item">
                                    <Link
                                        className={
                                            window.document.location.pathname === "/como-funciona"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to={"/como-funciona"}
                                    >
                                        <FontAwesomeIcon icon={faComment} className="mr-2" />
                                        Como funciona
                                    </Link>
                                </li>
                                {statusPagamento !== "PENDENTE" ? (
                                    <>
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    window.document.location.pathname === "/questionario/1"
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to={"/questionario/1"}
                                            >
                                                <FontAwesomeIcon icon={faBook} className="mr-2" />
                                                Etapa 1/3
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    window.document.location.pathname === "/questionario/2"
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to={"/questionario/2"}
                                            >
                                                <FontAwesomeIcon icon={faBook} className="mr-2" />
                                                Etapa 2/3
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className={
                                                    window.document.location.pathname === "/questionario/3"
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to={"/questionario/3"}
                                            >
                                                <FontAwesomeIcon icon={faBook} className="mr-2" />
                                                Etapa 3/3
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    ""
                                )}
                                <li className="nav-item">
                                    <Link
                                        className={
                                            window.document.location.pathname === "/duvidas"
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to={"/duvidas"}
                                    >
                                        <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                                        FAQ
                                    </Link>
                                </li>
                            </>
                        ) : (
                            ""
                        )}
                    </ul>
                    <div className="form-inline my-2 my-lg-0">
                        <div>
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="http://example.com"
                                        id="dropdown01"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {localStorage.getItem("userNameLogged")}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdown01">
                                        <Link className="dropdown-item" to={"/dados-pessoais"}>
                                            <FontAwesomeIcon icon={faList} className="mr-2" />
                                            Dados Pessoais
                                        </Link>
                                        <Link className="dropdown-item" to={"/alterar-senha"}>
                                            <FontAwesomeIcon icon={faCog} className="mr-2" />
                                            Alterar Senha
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button className="btn btn-outline-light" style={{ marginLeft: "10px" }} onClick={handleExit}>
                        <FontAwesomeIcon icon={faDoorOpen} className="mr-2" />
                        Sair
                    </button>
                </div>
            </div>
        </nav>
    );
}
