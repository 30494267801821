import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import Chatbot from "../../components/chatbot/Chatbot";
import ChatbotEtapa2 from "../../components/chatbotEtapa2/ChatbotEtapa2";
import ChatbotEtapa3 from "../../components/chatbotEtapa3/ChatbotEtapa3";
import Menu from "../../components/menu/Menu";
import api from "../../services/api";

export default function Questionario() {
    const [loading, setLoading] = React.useState(true);
    const [habilitaQuestionario, setHabilitaQuestionario] = React.useState("INATIVO");
    const [etapaConcluida, setEtapaConcluida] = React.useState(0);
    const { id } = useParams();
    const history = useHistory();
    const [listaPerguntas, setListPerguntas] = React.useState([]);
    const [idUsuario, setIdUsuario] = React.useState(0);

    React.useEffect(() => {
        async function ativoMenu() {
            try {
                const res = await api({
                    method: "GET",
                    url: `quiz/${id}/status`,
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                if (res.data.status === undefined) {
                    //Caso tentem burlar a URL colocando id de um QUIZ.
                    history.push("/");
                }
                setHabilitaQuestionario(res.data.status);
                setLoading(false);
            } catch (error) {
                alert("Error: " + error);
                setLoading(false);
                history.push("/");
            }
        }
        ativoMenu();
    }, [history, id]);

    React.useEffect(() => {
        async function listPerguntas() {
            try {
                const res = await api({
                    method: "get",
                    url: "me",
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                setIdUsuario(res.data.userIdLogado);

                const statusConcluiuEtapa = await api({
                    method: "get",
                    url: `/candidates/${res.data.userIdLogado}`,
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                let etapaConcluida = statusConcluiuEtapa.data.concluiu_etapa_1;

                if (id === "2") {
                    etapaConcluida = statusConcluiuEtapa.data.concluiu_etapa_2;
                } else if (id === "3") {
                    etapaConcluida = statusConcluiuEtapa.data.concluiu_etapa_3;
                }
                setEtapaConcluida(etapaConcluida);

                const questions = await api({
                    method: "GET",
                    url: `question/quiz/${id}`,
                    headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
                });
                setListPerguntas(questions.data.data);
            } catch (error) {
                alert("Error: " + error);
            }
        }

        listPerguntas();

        return () => {
            setListPerguntas([]);
        };
    }, [id]);

    return (
        <>
            <Menu />
            <main role="main" className="container">
                <Container fluid>
                    <div>
                        <div className="row">
                            <div className="col-sm-12">
                                {loading ? (
                                    <div className="alert alert-danger text-center" role="alert">
                                        <strong>Carregando...</strong>
                                    </div>
                                ) : habilitaQuestionario === "INATIVO" ? (
                                    <div className="alert alert-danger" role="alert">
                                        Esta etapa ainda não está <strong>ATIVA!</strong>
                                    </div>
                                ) : listaPerguntas.length > 0 && idUsuario !== 0 && etapaConcluida === 0 ? (
                                    id === "1" ? (
                                        <Chatbot perguntas={listaPerguntas} idUsuarioLogado={idUsuario} />
                                    ) : id === '2' ? (
                                        <ChatbotEtapa2 perguntas={listaPerguntas} idUsuarioLogado={idUsuario} />
                                    ) : <ChatbotEtapa3 perguntas={listaPerguntas} idUsuarioLogado={idUsuario} />
                                ) : etapaConcluida === 1 ? (
                                    <div className="alert alert-success" role="alert">
                                        <strong>Etapa concluída com êxito!</strong>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </main>
        </>
    );
}
