import React, { Component } from "react";
import PropTypes from "prop-types";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = sortableElement(({ value }) => (
    <li title="Clique e segure para mover" className="listMaterias">
        <div id="icone"></div>
        <div> {value}</div>
    </li>
));

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Grau de dificuldade</th>
                    <th style={{ textAlign: "center" }}>Matérias</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className="dificuldade">
                            <div className="div1">(+) facilidade</div>
                            <div className="div2">(+) dificuldade</div>
                        </div>
                    </td>
                    <td>
                        <ul style={{ padding: 0 }}>{children}</ul>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="2">
                        <p>
                            Arraste o ícone de cada disciplina para organizá-las por grau de facilidade. As disciplinas
                            que você tem mais afinidade primeiro até chegar por último naquelas que você acha mais
                            difíceis.
                        </p>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
});

class Materias extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trigger: false,
            items: ["Português", "Matemática", "Biologia", "Fisica", "Química", "Literatura", "História", "Geografia"],
        };

        this.triggetNext = this.triggetNext.bind(this);
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }));
    };

    triggetNext() {
        const val = this.state.items;
        this.setState({ trigger: true }, () => {
            this.props.triggerNextStep({ value: val.toString() });
        });
    }

    render() {
        const { items, trigger } = this.state;
        return (
            <div className="dbpedia" style={{ textAlign: "center" }}>
                <SortableContainer onSortEnd={this.onSortEnd}>
                    {items.map((value, index) => (
                        <SortableItem key={`item-${value}`} index={index} value={value} />
                    ))}
                </SortableContainer>
                {!trigger && (
                    <button className="btn btn-danger" onClick={() => this.triggetNext()}>
                        Confirmar Ordenação
                    </button>
                )}
            </div>
        );
    }
}

Materias.propTypes = {
    steps: PropTypes.object,
    triggerNextStep: PropTypes.func,
};

Materias.defaultProps = {
    steps: undefined,
    triggerNextStep: undefined,
};

export default Materias;
