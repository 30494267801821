import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "./index.css";
import PrivateRoute from "./components/content/PrivateRoute";
import Login from "./pages/login/Login";
import Candidatos from "./pages/candidatos/Candidatos";
import Questionarios from "./pages/administrarQuestionarios/Questionarios";
import Questionario from "./pages/questionario/Questionario";
import AlterarSenha from "./pages/alterarSenha/AlterarSenha";
import DadosPessoais from "./pages/dadosPessoais/DadosPessoais";
import ComoFunciona from "./pages/comoFunciona/ComoFunciona";
import VenhaProCave from "./pages/venhaProCave/VenhaProCave";
import Faq from "./pages/faq/Faq";
import Respostas from "./pages/respostas/Respostas";
import FaqExterna from "./pages/faqExterna/FaqExterna";
import EsqueciSenha from "./pages/esqueciSenha/EsqueciSenha";
import RedefinirSenha from "./pages/redefinirSenha/RedefinirSenha";

export default function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/esqueci-senha" component={EsqueciSenha} />
                <Route exact path="/redefinir-senha" component={RedefinirSenha} />
                <Route exact path="/venha-para-o-cave" component={VenhaProCave} />
                <Route exact path="/faq" component={FaqExterna} />
                <PrivateRoute exact path="/candidatos" component={Candidatos} />
                <PrivateRoute exact path="/questionarios" component={Questionarios} />
                <PrivateRoute exact path="/alterar-senha" component={AlterarSenha} />
                <PrivateRoute exact path="/como-funciona" component={ComoFunciona} />
                <PrivateRoute exact path="/duvidas" component={Faq} />
                <PrivateRoute exact path="/dados-pessoais" component={DadosPessoais} />
                <PrivateRoute exact path="/respostas/:idUsuario" component={Respostas} />
                <PrivateRoute path="/questionario/:id" children={<Questionario />} />
            </Switch>
        </BrowserRouter>
    );
}
