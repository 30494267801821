import React from "react";
import "../../assets/css/carousel.css";
import MenuLandingPage from "../../components/menuLandingPage/MenuLandingPage";
import fundo from "../../assets/destaque-cave.png";
import FormInscricao from "./components/FormInscricao";
import { Link } from "react-router-dom";

export default function VenhaProCave() {
    return (
        <>
            <MenuLandingPage />
            <main role="main">
                <div
                    className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light"
                    style={{ background: `url(${fundo}) no-repeat center` }}
                >
                    <div className="col-md-5 p-lg-5 mx-auto my-5">
                        <h1 className="display-4 font-weight-bold">Jornada de Acolhimento 9º ano 2024 Colégio CAVE!</h1>
                        <p className="lead font-weight-bold">Venha fazer parte de nossa história</p>
                        <p>
                            <a className="btn btn-danger my-2" href="#inscrever">
                                Inscreva-se Agora!
                            </a>
                            &nbsp;
                            <Link className="btn btn-secondary my-2" to="/">
                                Já sou inscrito
                            </Link>
                        </p>
                    </div>
                    <div className="product-device box-shadow d-none d-md-block"></div>
                    <div className="product-device product-device-2 box-shadow d-none d-md-block"></div>
                </div>

                <div className="container marketing">

                    <div className="row featurette" style={{ margin: "5rem 0" }}>
                        <div className="col-md-12">
                            <h1 className="text-center" id="inscrever">
                                Fazer minha inscrição!
                            </h1>
                            <p className="lead text-center">
                                Preencha os dados abaixo para iniciar seu processo de inscrição. Dúvidas?{" "}
                                <Link
                                    className="btn btn-sm btn-outline-danger my-2"
                                    title="Entenda o funcionamento"
                                    to={"/faq"}
                                >
                                    {" "}
                                    Acessar Aqui{" "}
                                </Link>
                            </p>

                            <FormInscricao />
                        </div>
                    </div>
                </div>

                <footer className="footer">
                    <div className="container p-2">
                        <span className="text-light">Cave 2023 © Todos os direitos reservados.</span>
                    </div>
                </footer>
            </main>
        </>
    );
}
