import { Formik, Form, Field } from "formik";
import React from "react";

export default function Checkbox({ triggerNextStep }) {
    const [btnVisible, setBtnVisible] = React.useState(true);
    const initialValores = {
        opcoes: "",
    };
    const handleSubmit = async (value) => {
        // console.log(value.opcoes);
        setBtnVisible(false);
        triggerNextStep({ value: value.opcoes.toString() });
    };
    return (
        <div>
            <Formik enableReinitialize initialValues={initialValores} onSubmit={handleSubmit} className="user">
                <Form>
                    <div className="row">
                        <div className="col col-12" style={{marginBottom: '15px'}}>Marque as opções abaixo:</div>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Pai" id="pai" />
                        <label htmlFor="pai" className="form-check-label">
                            Pai
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Mãe" id="mae" />
                        <label htmlFor="mae" className="form-check-label">
                            Mãe
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Avós" id="avos" />
                        <label htmlFor="avos" className="form-check-label">
                            Avós
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Irmãos" id="Irmãos" />
                        <label htmlFor="Irmãos" className="form-check-label">
                            Irmãos
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Pet" id="Pet" />
                        <label htmlFor="Pet" className="form-check-label">
                            Pet
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Amigos" id="Amigos " />
                        <label htmlFor="Amigos " className="form-check-label">
                            Amigos
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field
                            type="checkbox"
                            className="form-check-input"
                            name="opcoes"
                            value="Moro sozinho"
                            id="sozinho"
                        />
                        <label htmlFor="sozinho" className="form-check-label">
                            Moro sozinho
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Field type="checkbox" className="form-check-input" name="opcoes" value="Outros" id="outros" />
                        <label htmlFor="outros" className="form-check-label">
                            Outros
                        </label>
                    </div>
                    {btnVisible && (
                        <div className="form-check text-center" style={{marginTop: '15px'}}>
                            <button type="submit" className="btn btn-danger">
                                Enviar
                            </button>
                        </div>
                    )}
                </Form>
            </Formik>
        </div>
    );
}
