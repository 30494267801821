import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import api from "../../services/api";
import Checkbox from "./Checkbox";
import Materias from "./Materias";
// import user from "../../assets/img/user.png";
export default function Chatbot({ perguntas, idUsuarioLogado }) {
    // console.log(perguntas);
    // console.log('etapa1');
    const theme = {
        background: "#F5F8FB",
        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
        headerBgColor: "#f9c647",
        headerFontColor: "#A32927",
        headerFontSize: "16px",
        botBubbleColor: "#A32927",
        botFontColor: "#fff",
        userBubbleColor: "#fff",
        userFontColor: "#4c4c4c",
    };
    const config = {
        width: "100%",
        height: "77vh",
        headerTitle: "CONHECENDO MAIS SOBRE VOCÊ",
        placeholder: "Responda aqui...",
        // userAvatar: `${user}`,
    };
    const name = localStorage.getItem("userNameLogged");

    const steps = [
        {
            id: "1",
            message: `Olá! ${name}, :-)`,
            trigger: "2",
        },
        {
            id: "2",
            message: "Sou o Caveco e vou ser seu guia durante a Jornada de Acolhimento do Colégio CAVE.",
            trigger: "3",
        },
        {
            id: "3",
            message: "Vamos dar início à primeira etapa da nossa Jornada.",
            trigger: "5",
        },
        {
            id: "5",
            message: "Para isso, quero te conhecer melhor. :-). Vamos lá!",
            trigger: "6",
        },
        {
            id: "6",
            message: ` - ${perguntas[0].pergunta}`, //Como se chama a cidade em que você nasceu?
            trigger: "7",
        },
        {
            id: "7",
            user: true,
            trigger: "8",
            metadata: {
                idPergunta: perguntas[0].idPergunta,
            },
        },
        {
            id: "8",
            message: ` - ${perguntas[1].pergunta}`, //Você mora em Juiz de Fora?
            trigger: "9",
        },
        {
            id: "9",
            options: [
                { value: "Sim", label: "1) Sim", trigger: "15" },
                { value: "Não", label: "2) Não", trigger: "11" },
            ],
            metadata: {
                idPergunta: perguntas[1].idPergunta,
            },
        },
        {
            id: "11",
            message: ` - ${perguntas[2].pergunta}`, //Em qual cidade está morando atualmente?
            trigger: "12",
        },
        {
            id: "12",
            user: true,
            trigger: "13",
            metadata: {
                idPergunta: perguntas[2].idPergunta,
            },
        },
        {
            id: "13",
            message: "Cidade boa, temos muitos alunos de lá.",
            trigger: "16",
        },
        {
            id: "15",
            message: "Bacana, nós também somos genuinamente juiz-foranos!",
            trigger: "16",
        },
        {
            id: "16",
            message: `- ${perguntas[3].pergunta}`, //Qual é o seu endereço completo? Rua, número, complemento, bairro e CEP!
            trigger: "17",
        },
        {
            id: "17",
            user: true,
            trigger: "18",
            metadata: {
                idPergunta: perguntas[3].idPergunta,
            },
        },
        {
            id: "18",
            message: "Anotado!",
            trigger: "19",
        },
        {
            id: "19",
            message: `- ${perguntas[4].pergunta}`, //Habitualmente, com quem você divide sua casa?
            trigger: "20",
        },
        {
            id: "20",
            component: <Checkbox />,
            waitAction: true,
            trigger: "21",
            metadata: {
                idPergunta: perguntas[4].idPergunta,
            },
        },
        {
            id: "21",
            message: "Quero conhecer a sua trajetória que te trouxe até aqui!",
            trigger: "22",
        },
        {
            id: "22",
            message: `- ${perguntas[5].pergunta}`, //Em que colégio você estudou no Ensino Fundamental I? Se tiver sido em mais de um é só separar por vírgulas, ok?
            trigger: "23",
        },
        {
            id: "23",
            user: true,
            trigger: "24",
            metadata: {
                idPergunta: perguntas[5].idPergunta,
            },
        },
        {
            id: "24",
            message: "Ótimo!",
            trigger: "25",
        },
        {
            id: "25",
            message: `- ${perguntas[12].pergunta}`, //Em qual escola você estuda atualmente?
            trigger: "26",
        },
        {
            id: "26",
            user: true,
            trigger: "27",
            metadata: {
                idPergunta: perguntas[12].idPergunta,
            },
        },
        {
            id: "27",
            message: "Bacana!",
            trigger: "30",
        },
        // {
        //     id: "28",
        //     message: `- ${perguntas[7].pergunta}`, // Se você já cursou algum ano do Ensino Médio, eu gostaria de saber em que colégio! Se não, basta responder que 'ainda não'.
        //     trigger: "29",
        // },
        // {
        //     id: "29",
        //     user: true,
        //     trigger: "30",
        //     metadata: {
        //         idPergunta: perguntas[7].idPergunta,
        //     },
        // },
        {
            id: "30",
            message:
                "Beleza! Aqui no CAVE gostamos muito de ter pessoas de trajetórias diferentes. É onde todas as tribos se encontram!",
            trigger: "31",
        },
        {
            id: "31",
            message: `- ${perguntas[8].pergunta}`, //Todos temos facilidades e dificuldades e queremos conhecer as suas! Organize as disciplinas de acordo com a sua afinidade:
            trigger: "32",
        },
        {
            id: "32",
            component: <Materias />,
            waitAction: true,
            trigger: "33",
            metadata: {
                idPergunta: perguntas[8].idPergunta,
            },
        },
        {
            id: "33",
            message: perguntas[9] ? ` - ${perguntas[9].pergunta}` : "-", // Estou curioso para saber: como você conheceu o CAVE?
            trigger: "34",
        },
        {
            id: "34",
            user: true,
            trigger: "35",
            metadata: {
                idPergunta: perguntas[9] ? `${perguntas[9].idPergunta}` : "-",
            },
        },
        {
            id: "35",
            message: "Que legal! Estou feliz que agora temos a oportunidade de conhecer você também.",
            trigger: "36",
        },
        {
            id: "36",
            message: perguntas[10] ? ` - ${perguntas[10].pergunta}` : "-", // E você já conhece alguém no Colégio?
            trigger: "37",
        },
        {
            id: "37",
            options: [
                { value: "Sim", label: "Sim", trigger: "38" },
                { value: "Não", label: "Não", trigger: "43" },
            ],
            metadata: {
                idPergunta: perguntas[10] ? `${perguntas[10].idPergunta}` : "-",
            },
        },
        {
            id: "38",
            message: "Bacana!",
            trigger: "39",
        },
        {
            id: "39",
            message: perguntas[11] ? ` - ${perguntas[11].pergunta}` : "-", // Você pode me contar o nome da pessoa e a relação dela com o Colégio?
            trigger: "40",
        },
        {
            id: "40",
            user: true,
            trigger: "41",
            metadata: {
                idPergunta: perguntas[11] ? `${perguntas[11].idPergunta}` : "-",
            },
        },
        {
            id: "41",
            message: "Ótimo!",
            trigger: "44",
        },
        {
            id: "43",
            message: "Sem problemas! Tenho certeza que você terá tempo de conhecer várias pessoas.",
            trigger: "44",
        },
        {
            id: "44",
            message: `- ${name}, Chegamos ao fim por hoje! Para finalizarmos essa etapa verifique se você respondeu como gostaria e escolha uma das opções abaixo:`,
            trigger: "45",
        },
        {
            id: "45",
            options: [
                {
                    value: "Tudo certo! Enviar minhas informações.",
                    label: "1) Tudo certo! Enviar minhas informações.",
                    trigger: "46",
                },
                { value: "Reiniciar conversa", label: "2) Reiniciar conversa.", trigger: "1" },
            ],
        },
        {
            id: "46",
            message: `Beleza! Já registrei tudo. Prossiga para a segunda etapa. Até lá!`,
            end: true,
        },
    ];
    const handleEnd = async ({ steps, values }) => {
        // console.log(steps);
        // console.log(values);
        const respostas = steps.filter((item) => {
            return item.metadata !== undefined;
        });
        // console.log(respostas);
        const respostasTratada = respostas.map((item) => {
            return {
                resposta: item.value,
                idPergunta: item.metadata.idPergunta,
            };
        });

        try {
            await api({
                method: "POST",
                url: `answer`,
                data: {
                    respostas: respostasTratada,
                    idUsuarioLogado: idUsuarioLogado,
                    concluiu_etapa_1: true,
                },
                headers: { Authorization: `Barer ${localStorage.getItem("token")}` },
            });
        } catch (error) {
            alert(error);
        }
    };
    return (
        <div className="container">
            <ThemeProvider theme={theme}>
                <ChatBot
                    hideUserAvatar={true}
                    bubbleStyle={{ fontSize: "14px" }}
                    handleEnd={handleEnd}
                    steps={steps}
                    {...config}
                />
            </ThemeProvider>
        </div>
    );
}
